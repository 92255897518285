import { defineStore } from 'pinia'
import { v4 as uuidv4 } from 'uuid'
import { useToast } from 'vue-toastification'
import type {
  CartItem,
  Material,
  GroupOffer,
  PropertiesLookupValue,
  ItemStoreCart
} from '~~/composables/useMenuModel'

const {
  editMaterial,
  addMaterial,
  editOffer,
  reducePropertiesLookupValues,
  matchItems,
  deleteMaterial,
  editAddonsInCart,
  syncMaterials,
  removeMaterialsStore,
  addMaterialWithAddons,
  clearMaterials
} = useCartMethods()

export const useCartStore = () => {
  return defineStore('cart', {
    state: (): {
      items: CartItem[]
      stores_tables: {
        store_id?: string
        table_id?: string
        branch_id?: any
        table_number?: string
      }[]
      validatedItems: CartItem[]
      materialsCount: number
      loading: boolean
      uuid: string | null
      errors: Record<string, any>
    } => ({
      items: [],
      loading: false,
      stores_tables: [],
      validatedItems: [],
      materialsCount: 0,
      uuid: null,
      errors: {}
    }),

    getters: {
      getItem: (state) => {
        return (
          material: Material,
          addonsAttributes?: any,
          groupOfferId?: number
        ) => {
          const items = matchItems(
            state.items,
            material,
            addonsAttributes,
            groupOfferId
          )
          return items.length ? items[0]?.item : null
        }
      },
      isAuthLoggedIn: () => {
        return useMenuModel().isAuthLoggedIn()
      },

      quantity: (state) => {
        return (
          material: Material,
          addonsAttributes?: any,
          groupOfferId?: number
        ) => {
          const items = matchItems(
            state.items,
            material,
            addonsAttributes,
            groupOfferId
          )
          return items.length ? items[0]?.item?.quantity : 0
        }
      },
      itemsWithOutOffer: (state) => () => {
        return state.items.filter(
          (item) =>
            item.material_group_offer_id === undefined ||
            item.material_group_offer_id === null
        )
      },
      count: () => (items: CartItem[]) => {
        return items.length
      },

      getTableByID: (state) => (storeID: number) => {
        return state.stores_tables.find(
          (item) => parseFloat(item.store_id || '') === storeID
        )
      },
      badgeCount(): number | string {
        // @ts-ignore
        return this.getCount() > 9 ? '+9' : this.getCount()
      },
      itemsByStore: (state) => {
        const itemsLocal = [...state.items]
        /* gruop itemsByStore WithOut Sorted by offers */
        const itemsGroupedByStore = itemsLocal?.reduce(
          (r: Record<string, ItemStoreCart>, a: CartItem) => {
            if (a.material.store) {
              r[`${a.material.store.id}-${a.branch_id}`] = {
                id: a.material.store.id,
                branchId: a.branch_id,
                branch: a.branch,
                store: a.material.store,
                cartItems: [
                  ...(r[`${a.material.store.id}-${a.branch_id}`]?.cartItems ||
                    []),
                  a
                ]
              }
            }
            return r
          },
          {}
        )

        return itemsGroupedByStore
      } /* Sorted itemsByStore  by offers */,
      itemsByStoreSortedByOffer(): ItemStoreCart[] {
        const itemsLocal = this.itemsByStore
        const cartItemsPerStoreSorted = [] as ItemStoreCart[]
        Object.values(itemsLocal).forEach((item) => {
          const cartItemsPerStore = { ...item }
          cartItemsPerStore.items = {
            withOffers: cartItemsPerStore.cartItems?.reduce(
              (r: ItemsCartWithOffers, a: CartItem) => {
                if (a.group_offer_uuid) {
                  r[a.group_offer_uuid] = [...(r[a.group_offer_uuid] || []), a]
                  /*   if (r[a.group_offer_uuid]) {
                    r[a.group_offer_uuid].push(a)
                  } else {
                    r[a.group_offer_uuid] = []
                    r[a.group_offer_uuid].push(a)
                  } */
                }
                return r
              },
              {}
            ),
            withOutOffers: cartItemsPerStore.cartItems?.filter(
              (item: CartItem) =>
                item.material_group_offer_id === undefined ||
                item.material_group_offer_id === null
            )
          }
          delete cartItemsPerStore?.cartItems
          cartItemsPerStoreSorted.push(cartItemsPerStore)
        })

        return cartItemsPerStoreSorted
      },
      quantityItemsPerStore:
        (state) => (storelId: number, branchId?: number) => {
          const itemsLocal = [...state.items]
          return itemsLocal.reduce((r: number, a: CartItem) => {
            if (
              a.material?.store?.id === storelId &&
              a.branch_id === branchId
            ) {
              r = r + 1
            }
            return r
          }, 0)
        }
    },

    actions: {
      setValidationItems(items: CartItem[]) {
        this.validatedItems = items
      },
      setNewUuid() {
        this.uuid = this.uuid || uuidv4()
        return this.uuid
      },
      getUuid() {
        return this.uuid || this.setNewUuid()
      },

      setMaterialsCount(count?: number) {
        this.materialsCount = count || 0
      },
      setErrors(storeId: string | undefined, items: any) {
        if (storeId) {
          this.errors[storeId] = items
        }
      },
      isHasItemsByPerviousCityId(perviousCityId?: number):boolean {
        if (perviousCityId === null) {
          return false
        }

        return this.items.some(
          (item) => item.material?.store?.city_id === perviousCityId
        )
      },

      setItems(resultApi: any) {
        this.items = []
        this.setMaterialsCount(0)
        if (resultApi?.cart_items) {
          const itemsFiltered = resultApi?.cart_items.filter(
            (el: CartItem) => el.material?.store?.cart_visible === 1
          )
          this.setMaterialsCount(this.count(itemsFiltered))
          this.items = itemsFiltered
        }

        if (resultApi?.stores_tables) {
          this.stores_tables = resultApi?.stores_tables
        } else {
          this.stores_tables = []
        }
      },
      async addItem(
        material: Material,
        quantity?: number,
        propertiesLookupValues?: PropertiesLookupValue[],
        branchId?: number
      ) {
        const storeId = material.store?.id
          ? material.store.id.toString()
          : undefined

        return this.handleReponseApi(
          await addMaterial(
            material.id,
            quantity,
            propertiesLookupValues,
            branchId
          ),
          storeId
        )
      },
      async removeMaterialsStoreCart(storeId: number) {
        this.setErrors(storeId.toString(), undefined)
        this.handleReponseApi(
          await removeMaterialsStore(storeId),
          storeId.toString()
        )
      },
      async addItemWithAddonAttributes(
        material: Material,
        quantity = 1,
        addonsAttributes?: { addon_id: string; quantity: number }[],
        materialGroupOffe?: GroupOffer,
        propertiesLookupValues?: PropertiesLookupValue[],
        branchId?: number
      ) {
        const propertiesLookups = reducePropertiesLookupValues(
          propertiesLookupValues
        )
        const bodyRequest = {
          material_id: material.id,
          count: quantity,
          operation: '+',
          properties_lookup_values: propertiesLookupValues?.length
            ? propertiesLookupValues
            : undefined,
          propertiesLookupValues: propertiesLookups?.length
            ? propertiesLookups
            : undefined,
          material_group_offer_id: materialGroupOffe?.id,
          branch_id: branchId,
          addons: addonsAttributes
        } as any
        const storeId = material.store?.id
          ? material.store!.id.toString()
          : undefined
        this.setErrors(storeId, undefined)
        return this.handleReponseApi(
          await addMaterialWithAddons(bodyRequest),
          storeId
        )
      },
      async addOffer(materialGroupOfferID: number, storeId?: number) {
        const storeIdTemp = storeId ? storeId.toString() : undefined
        return await this.handleReponseApi(
          await editOffer({ material_group_offer_id: materialGroupOfferID }),
          storeIdTemp
        )
      },

      async deleteOffer(cartItem: CartItem) {
        const storeId = cartItem.material.store?.id
          ? cartItem.material.store.id.toString()
          : undefined
        return await this.handleReponseApi(
          await this.deleteServerItem({
            store_id: cartItem.material.store?.id,
            material_group_offer_id: cartItem.material_group_offer_id,
            group_offer_uuid: cartItem.group_offer_uuid
          }),
          storeId
        )
      },

      async deleteOrAddOffer(material: Material, GroupOfferId: number) {
        const itemCart = this.getItem(material, undefined, GroupOfferId)
        if (itemCart) {
          await this.deleteOffer(itemCart)
        } else {
          await this.addOffer(GroupOfferId, material.store?.id)
        }
      },
      async deleteItem(cartItemId: number, query?: object) {
        return await this.deleteServerItem(
          query || { cart_item_id: cartItemId }
        )
      },
      async toggleAddwithAddonsAttribute(
        material: Material,
        quantity = 1,
        addonsAttributes?: { addon_id: string; quantity: number }[],
        materialGroupOffer?: GroupOffer,
        propertiesLookupValues?: PropertiesLookupValue[],
        branchId?: number
      ) {
        if (addonsAttributes?.length) {
          return await this.addItemWithAddonAttributes(
            material,
            quantity,
            addonsAttributes,
            materialGroupOffer,
            propertiesLookupValues,
            branchId
          )
        } else {
          return await this.addItem(
            material,
            quantity,
            propertiesLookupValues,
            branchId
          )
        }
      },

      async editItem(
        material: Material,
        cartItemId: number,
        branchId: number | undefined,
        operationS: string,
        quantity?: number
      ) {
        const storeId = material.store?.id
          ? material.store!.id.toString()
          : undefined
        this.setErrors(storeId, undefined)
        return this.handleReponseApi(
          await editMaterial({
            material_id: material.id,
            cart_item_id: cartItemId,
            branch_id: branchId,
            count: quantity || 1,
            operation: operationS
          }),
          storeId
        )
      },
      clear() {
        this.items = []
        this.materialsCount = 0
        this.errors = {}
      },
      catchError(
        errorProps: any,
        storeId?: string,
        customMessageError?: string
      ) {
        const error =
          errorProps?.data && errorProps?.data?.statusCode
            ? errorProps?.data
            : errorProps
        const toast = useToast()
        const { t } = useNuxtApp().$i18n

        if (error?.statusCode === 422) {
          if (error?.data?.errors?.bill_detail_errors) {
            this.setErrors(
              storeId?.toString(),
              error?.data?.errors.bill_detail_errors
            )
            this.setValidationItems(error?.data.errors.new_bill_details)
          } else {
            const errArray = []
            if (error?.data?.errors) {
              for (const key in error?.data.errors) {
                if (Object.hasOwnProperty.call(error?.data.errors, key)) {
                  const element = error?.data.errors[key]
                  errArray.push(...element)
                }
              }
            } else if (error?.data?.message) {
              errArray.push(error?.data.message)
            }

            toast.error(
              errArray.length
                ? errArray[0]
                : customMessageError || t('error_500'),
              {
                timeout: 3000
              }
            )
            this.setErrors(storeId?.toString(), errArray)
          }
        } else {
          toast.error(
            customMessageError || error?.data?.message || t('error_500'),
            { timeout: 3000 }
          )
        }
      },

      async editAddonAttribute(
        material: Material,
        attributeId: string,
        cartItemId: number,
        operations: string,
        quantity = 1
      ) {
        const storeId = material.store?.id
          ? material.store!.id.toString()
          : undefined
        this.setErrors(storeId, undefined)
        return this.handleReponseApi(
          await editAddonsInCart(
            {
              material_id: material.id,
              addon_id: attributeId,
              cart_id: cartItemId,
              attribute_quantity: quantity,
              operation: operations
            },
            cartItemId
          ),
          storeId
        )
      },
      async deleteServerItem(data = {} as any) {
        const { t } = useNuxtApp().$i18n
        this.setErrors(data.store_id, undefined)
        return this.handleReponseApi(
          await deleteMaterial(data),
          data.store_id?.toString(),
          t('delete_failed')
        )
      },

      async serverLoad(initialLoading?: boolean) {
        if (!this.loading || initialLoading) {
          this.loading = true
          this.errors = {}

          const newCartResponse = await syncMaterials()
          this.loading = false

          this.handleReponseApi(newCartResponse, undefined, undefined, false)
        }
      },

      async serverClear() {
        this.errors = {}
        this.handleReponseApi(await clearMaterials())
      },

      getCount() {
        return this.materialsCount
      },
      handleReponseApi(
        reponseApi: any,
        storeId?: string,
        customMessageError?: string,
        withDeepValue?: boolean
      ) {
        const { data, error } = reponseApi || {}
        const dataValue = withDeepValue ? data?.value?.data : data?.value
        const errorValue = withDeepValue ? error?.value?.data : error?.value
        if (dataValue) {
          this.setItems(dataValue)
          return true
        } else if (errorValue) {
          this.catchError(errorValue, storeId, customMessageError)
          return false
        }
      }
    },

    persist: [
      { paths: ['uuid'], storage: persistedState.cookies },
      {
        paths: ['items', 'stores_tables', 'hasToken', 'loading'],
        storage: persistedState.localStorage
      }
    ]
  })()
}
export const useCheckout = () => {
  const loading = ref(false)

  function checkoutHandler() {
    loading.value = true

    // Handle Buy

    loading.value = false
  }

  return {
    loading,
    checkoutHandler
  }
}
